




















































































































































import base64Images from "@/modules/base64-images";
import Request from "@/modules/request";
import { MediaType } from "@/store/interfaces/MediaType";
import Coach from "@/store/models/Coach";
import MediaGalleryDialog from "@/components/MediaGalleryDialog.vue";
import Vue from "vue";
import rules from "@/modules/rules";

export default Vue.extend({
  props: {
    id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    MediaGalleryDialog,
  },
  computed: {
    thumbnailFile: {
      get(): File | null {
        return this.thumbnail as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.thumbnail = value;
              this.thumbnailSrc = URL.createObjectURL(value);
              this.thumbnailChanged = true;
            } else {
              this.thumbnail = null;
              this.thumbnailSrc = base64Images.userPlaceholder;
            }
          } else {
            this.thumbnail = value;
            this.thumbnailSrc = base64Images.userPlaceholder;
            this.thumbnailChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    coverFile: {
      get(): File | null {
        return this.cover as File;
      },
      set(value: File) {
        try {
          if (value) {
            if (this.validateThumbnail(value)) {
              this.cover = value;
              this.coverSrc = URL.createObjectURL(value);
              this.coverChanged = true;
            } else {
              this.cover = null;
              this.coverSrc = base64Images.thumbnailPlaceholder;
            }
          } else {
            this.cover = value;
            this.coverSrc = base64Images.thumbnailPlaceholder;
            this.coverChanged = true;
          }
        } catch (error) {
          this.$store.dispatch("showAlert", {
            message: error.message,
            color: "warning",
            timeout: 4000,
          });
        }
      },
    },
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.coach = null;
          this.coachObjectLoaded = false;
          this.coachObjectChanged = false;
          this.thumbnailSrc = base64Images.userPlaceholder;
          this.coverSrc = base64Images.thumbnailPlaceholder;
          (this.$refs.editCoachForm as any).resetValidation();
          this.$emit("close");
          setTimeout(() => {
            this.thumbnailFile = null;
            this.thumbnailChanged = false;
            this.updateLoading = false;
            this.gallerySrc = [];
          }, 300);
        }
      },
    },
  },
  data() {
    return {
      showGallery: false,
      coach: null as null | Coach,
      coachObjectLoaded: false,
      coachObjectChanged: false,
      thumbnailSrc: base64Images.userPlaceholder,
      thumbnailChanged: false,
      thumbnail: null as File | null,
      coverSrc: base64Images.thumbnailPlaceholder,
      coverChanged: false,
      cover: null as File | null,
      gallerySrc: [] as Array<string>,
      deleteDialog: false,
      deleteLoading: false,
      updateLoading: false,
      rules: rules(this),
      valid: false,
    };
  },
  watch: {
    async id() {
      // If ID, then we fetch the details
      if (this.id) {
        // Fetch video object
        this.getCoach();
      }
    },
    coach: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue && this.coachObjectLoaded) {
          this.coachObjectChanged = true;
        }
      },
    },
  },
  methods: {
    async updateCoach() {
      try {
        this.updateLoading = true;
        if (this.coach) {
          if (this.thumbnailChanged) {
            // If the user has deleted the Profile Pic, we send the appropriate request (an empty thumbnail route)
            if (!this.thumbnailFile) {
              const thumbnailDeleted = await Request.shared.delete(
                `coach/${this.coach.id}/profile`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.profile,
                alt: this.thumbnailFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.thumbnailFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedMedia = await Request.shared.put(
                `coach/${this.coach.id}/profile/${media.data.id}`
              );
            }
          }
          if (this.coverChanged) {
            if (!this.coverFile) {
              const coverDeleted = await Request.shared.delete(
                `coach/${this.coach.id}/cover`
              );
            } else {
              // The user has set a new thumbnail, we need to create the media and assign it to the video
              const mediaBody = {
                type: MediaType.cover,
                alt: this.coverFile.name,
              };
              // We create the formdata
              const formData = new FormData();
              formData.append("body", JSON.stringify(mediaBody));
              formData.append("media", this.coverFile);
              const media = await Request.shared.post("media", formData);
              // Once we got the media, we can assign the thumbnail to the video
              const assignedMedia = await Request.shared.put(
                `coach/${this.coach.id}/cover/${media.data.id}`
              );
            }
          }

          // We need to get rid of these includes to prevent wrecking havoc
          delete this.coach.ProfilePic;
          delete this.coach.CoverPic;
          // Update Coach
          const coachUpdated = await Request.shared.put(
            `coach/${this.coach.id}`,
            this.coach
          );
        }
        this.updateLoading = false;
        this.$store.dispatch("showAlert", {
          message: "Le coach a été mis à jour",
          color: "success",
          timeout: 4000,
        });
        // Rebuild original object
        await this.getCoach();
        this.$emit("updated");
        this.coachObjectChanged = false;
        this.thumbnailChanged = false;
        this.coverChanged = false;
      } catch (error) {
        console.error(error);
      }
    },
    async getCoach() {
      try {
        this.coachObjectLoaded = false;
        const coach = await Request.shared.get(`coach/${this.id}`);
        this.coach = coach.data;

        if (this.coach!.ProfilePic) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${this.coach?.ProfilePic.filename}`,
              {
                responseType: "blob",
              }
            );
            this.thumbnailSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        if (this.coach!.CoverPic) {
          try {
            const imageRequest = await Request.shared.get(
              `content/${this.$store.state.admin.uuid}/media/${this.coach?.CoverPic.filename}`,
              {
                responseType: "blob",
              }
            );
            this.coverSrc = URL.createObjectURL(imageRequest.data);
          } catch (error) {
            console.log(error);
            // Show alert but don't close
          }
        }
        this.coachObjectLoaded = true;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteCoach(id: number) {
      this.deleteLoading = true;
      await Request.shared.delete(`coach/${id}`);
      await this.$store.dispatch("getCoaches");
      this.deleteLoading = false;
      this.showDialog = false;
    },
    debug() {
      console.log("debug");
    },
    selectImage(ref: string) {
      (
        this.$refs?.[ref as keyof (Vue | Element | (Vue | Element)[])] as any
      ).$refs.input.click();
    },
    handleDrop(e: DragEvent, fileKey: string) {
      if (e.dataTransfer!.files.length) {
        (this as any)[fileKey] = e.dataTransfer!.files[0];
      }
    },
    validateThumbnail(file: File) {
      if (file.type == "image/png" || file.type == "image/jpeg") {
        // We check the file size
        if (file.size < 300000) {
          return true;
        } else {
          throw {
            message:
              "Le fichier est trop volumineux, sa taille doit être inférieure à 300ko",
          };
        }
      } else {
        throw {
          message: "Veuillez utiliser un fichier .jpg ou .png",
        };
      }
    },
  },
});
