




















































import Request from "@/modules/request";
import rules from "@/modules/rules";
import Vue from "vue";

export default Vue.extend({
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    showDialog: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        if (!value) {
          this.firstname = "";
          this.lastname = "";
          this.bio = "";
          this.description = "";
          (this.$refs.addCoachForm as any).resetValidation();
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      lastname: "",
      firstname: "",
      description: "",
      bio: "",
      addLoading: false,
      valid: false,
      rules: rules(this),
    };
  },
  methods: {
    async addCoach() {
      try {
        this.addLoading = true;
        await Request.shared.post(`coach`, {
          firstname: this.firstname,
          lastname: this.lastname,
          bio: this.bio,
          description: this.description,
        });
        this.addLoading = false;
        this.showDialog = false;
        this.$emit("add");
      } catch (error) {
        // TBD
      }
    },
  },
});
