


















































import Vue from "vue";
import { DataTableHeader } from "vuetify";
import EditCoachDialog from "@/components/EditCoachDialog.vue";
import AddCoachDialog from "@/components/AddCoachDialog.vue";

export default Vue.extend({
  components: {
    EditCoachDialog,
    AddCoachDialog,
  },
  async beforeMount() {
    await this.$store.dispatch("getCoaches");
  },
  data: () => ({
    editIndex: 0,
    editDialog: false,
    addDialog: false,
    headers: [
      {
        text: "Photo de profil",
        value: "profilePicSrc",
        width: 120,
        sortable: false,
      },
      {
        text: "Nom",
        value: "formattedName",
      },
      {
        text: "",
        value: "tools",
        width: 50,
      },
    ] as DataTableHeader[],
  }),
  methods: {
    postUpdateCycle() {
      this.$store.dispatch("getCoaches");
    },
    openEditDialog(item: Record<string, any>) {
      this.editIndex = item.id;
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editIndex = 0;
      this.editDialog = false;
    },
  },
});
